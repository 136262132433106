@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .btn-quiz {
        @apply inline-block text-sm px-6 py-3 mx-3 my-2 border border-green-700 text-green-700 rounded tracking-wider transition duration-500 hover:bg-green-700 hover:text-white focus:outline-none;
    }
    .btn-quiz--selected {
        @apply bg-green-700 text-white;
    }
    .btn-quiz--invalid {
        @apply border-yellow-600 bg-yellow-600 text-white hover:bg-yellow-600;
    }
}